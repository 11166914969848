Mixins.cookies ={
    data() {
        return {
            cookieConsent: {
                necessary: false,
                preferences: false,
                statistics: false,
                marketing: false,
            }
        }
    },
    created() {
        let cookie =  document.cookie.match('(^|;)\\s*CookieScriptConsent\\s*=\\s*([^;]+)').pop() || false
        if (cookie) {
            let cookieObj = JSON.parse(cookie);

            this.cookieConsent.strict = Object.values(cookieObj.googleconsentmap).includes("strict");
            this.cookieConsent.performance = Object.values(cookieObj.googleconsentmap).includes("performance");
            this.cookieConsent.targeting = Object.values(cookieObj.googleconsentmap).includes("targeting")
            this.cookieConsent.unclassified = Object.values(cookieObj.googleconsentmap).includes("unclassified")
        }
    }
}
