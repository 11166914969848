Vue.component('inspiration-detail', {
    data: function () {
        return {
            id: 0,
            next: '',
            prev: '',
            prevTitle: '',
            nextTitle: ''
        }
    },
    mixins: [Mixins.inspirations],
    computed: {
        nextClassObject: function() {
            return {
                'd-inline-flex': (this.next !== ''),
                'd-none': (this.next === '')
            }
        },

        prevClassObject: function() {
            return {
                'd-inline-flex': (this.prev !== ''),
                'd-none': (this.prev === '')
            }
        }
    },
    mounted: function () {
        this.id = parseInt(this.$el.dataset.id);
        this.getSurroundings();
    },
    methods: {
        getSurroundings: function () {
            let currentFound = false;

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];

                if (item.id === this.id) {
                    currentFound = true;
                    continue;
                }

                if (! currentFound) {
                    this.prev = item.url;
                    this.prevTitle = item.title;
                } else if (i !== this.items.length){
                    this.next = item.url;
                    this.nextTitle = item.title;
                    break;
                }
            }

            if (this.next === this.prev) {
                this.next = '';
                this.nextTitle = '';
            }

            if (this.next === '' && this.showMore) {
                this.loadMore().then((response) => {
                    this.getSurroundings();
                });
            }
        }
    }
});
