Vue.component('warehouse-slider', {
    data: function () {
        return {
            warehouseSlider: null,

            warehouseSlideUrl: null,
            warehouseSlideLink: null,
            warehouseSlideCaption: null,
            warehouseSlideCount: null,
        }
    },
    mounted: function () {
        this.initializeHighlightedSlider();
        this.setSlideInfo();
    },
    methods: {
        setSlideInfo: function(){
            this.warehouseSlideUrl = this.warehouseSlider.selectedElement.dataset.url;
            this.warehouseSlideLink = this.warehouseSlider.selectedElement.dataset.link;
            this.warehouseSlideCaption = this.warehouseSlider.selectedElement.dataset.caption;
            this.warehouseSlideCount = this.warehouseSlider.selectedElement.dataset.iterator;
        },

        initializeHighlightedSlider: function () {
            let that = this;

            this.warehouseSlider = new Flickity(this.$refs.warehouseSlider, {
                pageDots: false,
                imagesLoaded: true,
                wrapAround: true,
                prevNextButtons: false,
                slides: 1,

                on: {
                    change: function(index) {
                        that.setSlideInfo();
                    }
                }
            });
        },

        nextHighlightedSlide: function() {
            this.warehouseSlider.next(true, false);
        },
        prevHighlightedSlide: function() {
            this.warehouseSlider.previous(true, false);
        }
    }
});