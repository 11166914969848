Vue.component('slider-content', {
    data: function () {
        return {
            contentSlider: null,
        }
    },
    mounted: function () {
        this.initializeContentSlider();
    },
    methods: {
        setReady: function(){
            this.$el.classList.add('loaded');
        },

        initializeContentSlider: function () {
            let that = this;

            let slides = this.$el.getElementsByClassName('content-slide');
            if (slides.length === 1) {
                this.$el.querySelector('.content-slider__controls').remove();
            }

            this.contentSlider = new Flickity(document.querySelector(".content-slider__inner"), {
                pageDots: false,
                wrapAround: true,
                cellAlign: 'left',
                prevNextButtons: false,

                on: {
                    ready: function() {
                        that.setReady();
                    }, change: function(){
                        var bLazy = new Blazy();
                    }
                }
            });
        },

        nextContentSlide: function () {
            this.contentSlider.next(true, false);
        },
        prevContentSlide: function () {
            this.contentSlider.previous(true, false);
        }
    }
});