Vue.component('system-pointers', {
    data: function () {
        return {
            active: 0
        }
    },
    methods: {
        open: function (index) {
            if (index !== this.active) {
                this.active = index;
            }
        },

        close: function () {
            setTimeout(() => {
                this.active = 0;
            }, 50);
        }
    },
});