Vue.component('slider-team', {
    data: function () {
        return {
            teamSlider: null,
            teamSliderReady: false
        }
    },
    mounted: function () {
        this.initializeTeamSlider();
    },
    methods: {
        initializeTeamSlider: function () {
            let that = this;

            this.teamSlider = new Flickity(this.$refs.teamSlider, {
                pageDots: false,
                imagesLoaded: true,
                adaptiveHeight: false,
                wrapAround: true,
                prevNextButtons: false,
                slides: 1,

                on: {
                    ready: function () {
                        window.dispatchEvent(new Event('resize'));
                        that.teamSliderReady = true;
                    },

                    change: function() {
                        if (description && description.length && description.length > 0) {
                            setTimeout(function () {
                                // window.dispatchEvent(new Event('resize'));
                            }, 200);
                        }
                    }
                }
            });
        },

        nextTeamSlide: function() {
            this.teamSlider.next(true, false);
        },

        prevTeamSlide: function() {
            this.teamSlider.previous(true, false);
        }
    }
});
