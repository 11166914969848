Vue.component('slider-highlighted', {
    data: function () {
        return {
            highlightedSlider: null,

            highlightSlideUrl: null,
            highlightSlideLink: null,
            highlightSlideCaption: null,
            highlightSlideCount: null,
        }
    },
    mounted: function () {
        this.initializeHighlightedSlider();
        this.setSlideInfo();
    },
    methods: {
        setSlideInfo: function(){
            this.highlightSlideUrl = this.highlightedSlider.selectedElement.dataset.url;
            this.highlightSlideLink = this.highlightedSlider.selectedElement.dataset.link;
            this.highlightSlideCaption = this.highlightedSlider.selectedElement.dataset.caption;
            this.highlightSlideCount = this.highlightedSlider.selectedElement.dataset.iterator;
        },

        initializeHighlightedSlider: function () {
            let that = this;

            this.highlightedSlider = new Flickity(this.$refs.highlightedSlider, {
                pageDots: false,
                imagesLoaded: true,
                wrapAround: true,
                prevNextButtons: false,
                slides: 1,
                autoPlay: true,

                on: {
                    change: function() {
                        that.setSlideInfo();
                    }
                }
            });
        },

        nextHighlightedSlide: function() {
            this.highlightedSlider.next(true, false);
        },

        prevHighlightedSlide: function() {
            this.highlightedSlider.previous(true, false);
        }
    },
});
