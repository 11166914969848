Vue.component('slider-milestones', {
    data: function () {
        return {
            milestonesSlider: null,
            milestonesSliderNav: null
        }
    },
    mounted: function () {
        this.initializeMilestonesSlider();
        this.initializeMilestonesSliderNav();
    },
    methods: {
        initializeMilestonesSlider: function () {
            let that = this;

            let elem = document.querySelector(".milestones-slider");
            this.milestonesSlider = new Flickity(elem, {
                contain: true,
                pageDots: false,
                imagesLoaded: true,
                wrapAround: true,
                prevNextButtons: false,
                selectedAttraction: 1,
                adaptiveHeight: true,
                friction: 1,
                slides: 1,
                initialIndex: '.initial-select',
            });
        },

        initializeMilestonesSliderNav: function () {
            let that = this;

            let elem = document.querySelector(".milestones-navigation");

            this.milestonesSliderNav = new Flickity(elem, {
                contain: true,
                pageDots: false,
                imagesLoaded: true,
                wrapAround: true,
                prevNextButtons: false,
                asNavFor: '.milestones-slider',
                initialIndex: '.initial-select-nav',

            });
        },
        nextMilestonesSlide: function() {
            this.milestonesSlider.next(true, false);
        },
        prevMilestonesSlide: function() {
            this.milestonesSlider.previous(true, false);
        }
    }
});
