Vue.component('inspiration-overview', {
    data: function () {
        return {
            activeFilter: null,
            filterChanged: false
        }
    },
    mixins: [Mixins.inspirations],
    methods: {
        toggleFilter: function(int) {
            if (this.activeFilter == int) {
                this.activeFilter = null;
            } else {
                this.activeFilter = int;
            }
        },

        closeFilter: function() {
            const that = this;

            that.$el.classList.add('inspiration-overview--loading');
            this.activeFilter = null;

            setTimeout(function () {
                that.$el.classList.remove('inspiration-overview--loading');
            }, 250)
        },

        reset: function () {
            this.page = 1;
            this.$refs.filter.reset();

            let inputs = this.$refs.filter.getElementsByTagName('input');
            for (let index = 0; index < inputs.length; index++) {
                let input = inputs[index];

                if (input.type === 'checkbox') {
                    input.checked = false;
                }
            }

            this.closeFilter();
            this.getItems(true);
        }
    },

    mounted: function () {
        window.addEventListener('scroll', (event) => {
            if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 5)) {
                this.loadMore();
            }
        });
    }
});