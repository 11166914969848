Vue.component('slider-colors', {
    data: function () {
        return {
            colorsSlider: null,
        }
    },
    mounted: function () {
        this.initializeColorsSlider();
    },
    methods: {
        initializeColorsSlider: function () {
            let elem = document.querySelector(".warehouse__colors");
            this.colorsSlider = new Flickity(elem, {
                pageDots: false,
                wrapAround: true,
                cellAlign: 'left',
                prevNextButtons: false,
                autoPlay: 2000
            });
        },
        nextHighlightedSlide: function() {
            this.colorsSlider.next(true, false);
        },
        prevHighlightedSlide: function() {
            this.colorsSlider.previous(true, false);
        }
    }
});