Mixins.inspirations = {
    data: function () {
        return {
            page: 1,
            items: [],
            loading: false,
            jsonLoaded: false,
            lastPage: 0,
            filterChanged: false
        };
    },
    computed: {
        showMore: function () {
            return (this.lastPage > this.page);
        }
    },
    mounted: function () {
        let cached = {};
        if (typeof (Storage) !== 'undefined') {
            cached = JSON.parse(localStorage.getItem('inspiration-' + document.documentElement.lang));

            if (this.$refs.list) {
                localStorage.setItem('inspiration-url', window.location.pathname);
            }
        }


        let json = {};
        if (this.$refs.json) {
            json = JSON.parse(this.$refs.json.innerHTML);
            // this.jsonLoaded = true;
        }

        let load = null;
        if (cached && typeof cached === 'object' && cached.hasOwnProperty('items') && (json.length === undefined || cached.items.length > json.length)) {
            load = 'storage';
        } else if (json && json.length !== undefined && json.length > 0) {
            load = 'json';
        }

        if (load === 'storage') {
            if (cached.hasOwnProperty('items')) {
                this.items = cached.items;
            }

            if (cached.hasOwnProperty('lastPage')) {
                this.lastPage = parseInt(cached.lastPage);
            }

            if (cached.hasOwnProperty('page')) {
                this.page = parseInt(cached.page);
            }
        } else if (load === 'json'){
            this.items = json;

            if (this.$refs.list) {
                this.lastPage = parseInt(this.$refs.list.dataset.lastPage);
            }
        } else {
            this.getItems(this.getParameter('reset') === 'true');

            if (this.$refs.list) {
                this.lastPage = parseInt(this.$refs.list.dataset.lastPage);
            }
        }
    },
    watch: {
        items: function (val, oldVal) {
            if (typeof (Storage) !== 'undefined') {
                localStorage.setItem('inspiration-' + document.documentElement.lang, JSON.stringify({
                    items: val,
                    lastPage: this.lastPage,
                    page: this.page
                }));
            }
        }
    },
    methods: {
        getParameter: function(name, url) {
            name = name.replace(/[\[\]]/g, '\\$&');

            if (! url) {
                url = window.location.href;
            }

            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            var results = regex.exec(url);

            if (!results) {
                return null;
            }

            if (!results[2]) {
                return '';
            }

            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },

        getItems: function(reset) {
            if (this.loading || (! this.showMore && ! this.filterChanged)) {
                return false;
            }

            if (reset) {
                this.$data.page = 1;
            }

            let queryString = serialize(this.$refs.filter);
            if (queryString) {
                queryString += '&page=' + this.page;
            } else {
                queryString += 'page=' + this.page;
            }

            let url = location.protocol + '//' + location.host + '/' + document.documentElement.lang + '/api/inspiration?' + queryString;

            this.loading = true;

            return axios.get(url).then((response) => {
                this.loading = false;
                this.filterChanged = false;

                let meta = response.data.meta;
                this.lastPage = meta.last_page;

                let newItems = response.data.data;
                if (! reset) {
                    for (let index in newItems) {
                        this.items.push(newItems[index]);
                    }
                } else {
                    this.items = newItems;
                }

                if (this.$el.dataset.id !== undefined) {
                    return true;
                }

                if (! this.$refs.list) {
                    return true;
                }

                return true;
            }).catch((error) => {
                this.loading = false;
                console.log(error);
                return false;
            });
        },

        loadMore: function () {
            this.page = parseInt(this.page) + 1;

            return this.getItems(false);
        },
    }
};
