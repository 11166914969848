Vue.component('form-info', {
    data: function () {
        return {
            country: ''
        }
    },
    computed: {
        showExtraAddressLines: function () {
            if (this.country === '' || this.country.toLowerCase() === 'nederland') {
                return false;
            }

            return true;
        }
    },
    methods: {
        submit: function (event) {
            // Needed to correctly validate form before submitting in global submit
            this.$validator.validateAll().then(result => {
                if (result) {
                    return this.$parent.submit(event);
                }
            });
        }
    }
});